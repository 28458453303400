<template>
  <NavBar/>
  <main>
    <div class="container">
      <div class="wrapper">
        <div class="box">
          <h5 class="title-text">404</h5>
        </div>
        <div class="box">
          <p class="not-found-text">Sorry, page not found</p>
        </div>
        <div class="box">
          <router-link to="/">
            <button class="home-button">Go to Home</button>
          </router-link>
        </div>
      </div>
    </div>
  </main>
  <Footer/>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/TheFooter.vue";

export default {
  name: "PageNotFound",
  components: {
    NavBar,
    Footer
  }
}

</script>

<style lang="scss" scoped>
  main {
    justify-content: center;
  }
  hr {
    max-width: 1200px;
    margin: 0 auto;
  }
  .container {
    margin: 0 auto;
    max-width: 800px;
    height: 100%;
    font-family: "librefranklin-extrabold", sans-serif;
    text-align: center;
    color: #666666;
    .wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
        .box {
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 200px;
          margin: 25px 0;
          .title-text {
            font-size: 216px;
            font-weight: bold;
            letter-spacing: 1rem;
          }
          .not-found-text {
            font-size: 42px;
            letter-spacing: 0.2rem;
          }
          .home-button {
            height: 50px;
            width: 300px;
            border: none;
            color: white;
            font-size: 16px;
            font-weight: bold;
            background-color: #00ADB7;
            &:hover {
              cursor: pointer;
              background-color: #00dde9;
            }
          }
        }
    }
  }

  @media (max-width: 500px) {
    .container {
      height: 100%;
      margin: 0 20px;
      .wrapper {
        .box {
          .title-text {
            font-size: 150px;
          }
          .not-found-text {
            font-size: 32px;
          }
          .home-button {
            max-width: 250px;
          }
        }
      }
    }
  }
</style>